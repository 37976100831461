.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer {
  right: -5px !important;
  z-index: 0 !important;
}

.rt-tbody {
  overflow: initial !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
}

.ReactTable .rt-th .rt-resizer:before {
  cursor: pointer;
  color: black;
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  color: transparent;
  content: '▵';
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before,
.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  color: black;
  transition: all 0.2s ease;
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  transform: rotate(0deg) translateY(0px);
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  transform: rotate(180deg) translateY(-3px);
}
